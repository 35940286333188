interface IParamsScrollToElement {
  offseetTop?: number;
}

const scrollToElement = (identificatory: string, params?: IParamsScrollToElement) => {
  const element: Element | null = document.querySelector(identificatory);
  if (!element) {
    return
  }

  const parentTop = element.getBoundingClientRect().top + window.pageYOffset;
  const headerHeight = document.querySelector('.header-wrapper')?.getBoundingClientRect().height || 0;

  let topPosition = parentTop - headerHeight;
  if (params?.offseetTop) {
    topPosition = topPosition - params?.offseetTop;
  }

  window.scrollTo({ top: topPosition });
}
export {
  scrollToElement
}
